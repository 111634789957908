body {
  font-family: 'Arial', sans-serif;
  background-color: #ffe6f2;
  color: #ff99cc;
  text-align: center;
  padding: 20px;
}

header {
  background-color: #ffccf2;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 20px;
}

header a.discord-link {
  color: #86cecb;
  text-decoration: none;
  transition: color 0.3s;
}

header a.discord-link:hover {
  color: #70a8a7; /* Slightly darker when hovered over */
}

header a.discord-link:visited {
  color: #86cecb; /* Prevents turning purple when clicked */
}

#discord-username {
  color: #137a7f;
}

nav ul {
  list-style: none;
  padding: 0;
}

nav ul.nav-buttons {
  display: flex;
  justify-content: center;
  gap: 30px;
  padding: 0;
}

nav ul.nav-buttons li {
  display: inline-block;
}

nav ul.nav-buttons a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  position: relative;
  text-decoration: none;
  font-size: 14px;
  color: #86cecb; /* Same color as discord.gg URL */
  transition: transform 0.3s;
  background-color: #ff6699; /* Same color as "Welcome to my page" text */
  border-radius: 50%;
  border: 2px solid #ff99cc;
}

nav ul.nav-buttons a ion-icon {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  fill: #ff99cc; /* Same color as "Welcome to my page" text */
}

nav ul.nav-buttons a span {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-size: 14px;
  color: #86cecb; /* Same color as discord.gg URL */
}

nav ul.nav-buttons a:hover {
  transform: scale(1.2);
}

.large-font {
  font-size: xxx-large;
}

.top-20 {
  position: relative;
  top: 20vh;
}

ion-icon.active {
  animation: like 0.5s 1;
  fill: red;
  stroke: none;
}

ion-icon {
  fill: transparent;
  stroke: black;
  stroke-width: 30;
  transition: all 0.5s;
}

.lover {
  color: #ffb6c1; /* Light pink color */
  display: inline-block;
  animation: pulseTilt 1.5s infinite;
}

@keyframes like {
  0% { transform: scale(1); }
  90% { transform: scale(1.2); }
  100% { transform: scale(1.1); }
}

@keyframes pulseTilt {
  0%, 100% {
    transform: scale(1) rotate(0deg);
  }
  50% {
    transform: scale(1.2) rotate(10deg);
  }
  75% {
    transform: scale(1.2) rotate(-10deg);
  }
}
